/* ModalStyles.css */

/* Two-column grid for products */
.product-grid {
    display: grid;
    grid-template-columns: repeat(2, 1fr); /* Two columns */
    gap: 10px; /* Space between items */
  }
  
  /* Custom scrollable container */
  .scroll-container {
    max-height: 300px; /* Adjust based on your design */
    overflow-y: auto;  /* Enable scrolling */
    padding-right: 10px; /* Space for custom scrollbar */
  }
  
  /* Individual product items */
  .product-item {
    display: flex;
    align-items: center;
  }
  
  /* Optional: Custom scrollbar styling */
  .scroll-container::-webkit-scrollbar {
    width: 6px; /* Width of the scrollbar */
  }
  
  .scroll-container::-webkit-scrollbar-thumb {
    background-color: #888; /* Color of the scrollbar thumb */
    border-radius: 10px;
  }
  
  .scroll-container::-webkit-scrollbar-thumb:hover {
    background-color: #555; /* Color when hovering over the scrollbar */
  }
  
  .scroll-container::-webkit-scrollbar-track {
    background-color: #f1f1f1; /* Scrollbar track background */
  }
  