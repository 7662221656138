/* modalStyles.css */
.custom-modal {
    max-width: 60%;
    width: 100%;
  }
  
  @media (max-width: 768px) {
    .custom-modal {
      max-width: 100%;
    }
  }
  