.composemodal .modal-dialog {
    width: 100%;
    height: 100%;
    margin: 0;
    padding: 0;
    max-width: 100%;
  }
  
  .composemodal .modal-content {
    height: 100%;
    border-radius: 0;
  }
  
  .composemodal .modal-body {
    height: calc(100% - 56px); /* Adjust for header and footer height */
    overflow-y: auto;
  }
  