//
// authentication.scss
//

.auth-body-bg {
    background-color: $card-bg;
}

.authentication-bg {
    background-image: url("../../../images/login_image.jpg");
    height: 100vh;
    background-size: cover;
    background-position: center;
    // .bg-overlay {
    //     // background-color: #292626;
    // }

    @media (max-width: 991px) {
        display: none;
    }
}

.authentication-page-content {
    height: 100vh;
    display: flex;
}

.auth-form-group-custom {
    position: relative;
    .form-control {
        height: 30px;
        padding-top: 14px;
        padding-left: 30px;
    }
    .form-control[type="file"] {
        height: 40px; /* Adjust the height as needed */
        padding-top: 14px; /* Adjust the top padding as needed */
        padding-left: 30px; /* Adjust the left padding as needed */
    }

    label {
        position: absolute;
        top: 3px;
        left: 30px;
    }

    .auti-custom-input-icon {
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        left: 19px;
        font-size: 12px;
        color: $primary;
    }
}

.auth-logo {
    &.logo-light {
        display: $display-none;
    }
    &.logo-dark {
        display: $display-block;
    }
}

[data-bs-theme="dark"] {
    .authentication-logo {
        .logo-light {
            display: block;
        }
        .logo-dark {
            display: none;
        }
    }
}
